import SvgIcon from './SvgIcon/index.vue'
import MyCard from './Card/index.vue'
import MyInput from './Input/index.vue'
import MyEchart from './Echart/index.vue'
import MyButton from './Button/index.vue'
import MyDeleteButton from './Button/delete.vue'
import MyTable from './Table/index.vue'
import MyLoadingButton from './Button/loading.vue'
import MyBox from './Box/index.vue'
import MySelect from './Select/index.vue'
//引入element-plus提供的全部icons
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//定义全局对象
const allGlobalComponent: any = {
  SvgIcon,
  MyCard,
  MyInput,
  MyEchart,
  MyButton,
  MyDeleteButton,
  MyTable,
  MyLoadingButton,
  MyBox,
  MySelect,
}
//对外暴露插件对象
export default {
  //务必叫做install组件
  install(app: any) {
    //注册项目全部的全局组件
    Object.keys(allGlobalComponent).forEach((key) => {
      app.component(key, allGlobalComponent[key])
    })
    //icons
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }
  },
}
