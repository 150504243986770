// 静态路由
export const constantRoute = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    name: 'login',
    meta: {
      title: 'route.login',
      hidden: true,
      icon: 'Promotion',
    },
  },

  {
    path: '/',
    component: () => import('@/layout/index.vue'),
    name: 'layout',
    meta: {
      title: 'route.welcome',
      hidden: false,
      icon: 'Location',
    },
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/index.vue'),
        name: 'Home',
        meta: {
          title: 'route.home',
          hidden: false,
          icon: 'HomeFilled',
          fa: 'fas fa-tachometer-alt-fast fa-6x customer-color',
        },
      },
    ],
  },

  {
    path: '/dashboard',
    component: () => import('@/layout/index.vue'),
    name: '',
    meta: {
      title: '',
      hidden: false,
      icon: '',
    },
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard/index.vue'),
        name: 'Dashboard',
        meta: {
          title: 'route.dashboard',
          hidden: false,
          icon: 'Platform',
        },
      },
    ],
  },

  {
    path: '/resource',
    component: () => import('@/layout/index.vue'),
    name: 'resource_center',
    meta: {
      title: 'route.resource_center',
      hidden: false,
      icon: 'GoodsFilled',
    },
    redirect: '/resource/overview',
    children: [
      {
        path: '/resource/overview',
        component: () => import('@/views/resource/overview/index.vue'),
        name: 'scenario_overview',
        meta: {
          title: 'route.scenario_overview',
          hidden: false,
          icon: 'View',
        },
      },

      {
        path: '/resource/overview/detail/:id',
        component: () => import('@/views/resource/overview/detail/index.vue'),
        name: 'scenario_detail',
        meta: {
          title: 'route.scenario_detail',
          hidden: true,
          icon: 'Stamp',
        },
      },

      {
        path: '/resource/overview/video/:id?',
        component: () => import('@/views/resource/overview/video/index.vue'),
        name: 'video_preview',
        meta: {
          title: 'route.video_preview',
          hidden: true,
          icon: 'VideoCameraFilled',
        },
      },

      {
        path: '/resource/scenario_file',
        component: () => import('@/views/resource/simulation_file/index.vue'),
        name: 'scenario_file',
        meta: {
          title: 'route.scenario_file',
          hidden: false,
          icon: 'Document',
        },
      },

      {
        path: '/resource/scenario_file/detail/id=:id',
        component: () => import('@/views/resource/simulation_file/detail/index.vue'),
        name: 'scenario_file_detail',
        meta: {
          title: 'route.scenario_file_detail',
          hidden: true,
          icon: 'Document',
        },
      },

      {
        path: '/resource/param&kpi',
        component: () => import('@/views/resource/param_kpi/index.vue'),
        name: 'param_kpi',
        meta: {
          title: 'route.param_kpi',
          hidden: false,
          icon: 'DataLine',
        },
      },

      {
        path: '/resource/vehicle',
        component: () => import('@/views/resource/vehicle_model/index.vue'),
        name: 'vehicle',
        meta: {
          title: 'route.vehicle',
          hidden: false,
          icon: 'Van',
        },
      },

      {
        path: '/resource/vehicle/config',
        component: () =>
          import('@/views/resource/vehicle_model/configure_car/index.vue'),
        name: 'vehicle_configuration',
        meta: {
          title: 'route.ConfigureVehicle',
          hidden: true,
          icon: 'Van',
        },
      },

      {
        path: '/resource/vehicle/detail/:name?',
        component: () =>
          import('@/views/resource/vehicle_model/detail/index.vue'),
        name: 'vehicle_detail',
        meta: {
          title: 'route.vehicle_info',
          hidden: true,
          icon: 'Van',
        },
      },

      {
        path: '/resource/suts',
        component: () => import('@/views/resource/suts/index.vue'),
        name: 'suts',
        meta: {
          title: 'route.suts',
          hidden: false,
          icon: 'Cpu',
        },
      },

      {
        path: '/resource/valdivia',
        component: () => import('@/views/resource/valdivia/index.vue'),
        name: 'valdivia_config',
        meta: {
          title: 'route.valdivia_config',
          hidden: false,
          icon: 'Compass',
        },
      },

      {
        path: '/resource/llm',
        component: () =>
          import('@/views/resource/simulation_file/llm/index.vue'),
        name: 'llm',
        meta: {
          title: 'route.llm',
          hidden: true,
          icon: 'MagicStick',
        },
      },
    ],
  },

  {
    path: '/task_center',
    component: () => import('@/layout/index.vue'),
    name: 'task_center',
    meta: {
      title: 'route.task_center',
      hidden: false,
      icon: 'List',
    },
    redirect: '/task_center/task_management',
    children: [
      {
        path: '/task_center/task_management',
        component: () => import('@/views/task/management/index.vue'),
        name: 'task_management',
        meta: {
          title: 'route.task_management',
          hidden: false,
          icon: 'Coordinate',
        },
      },

      {
        path: '/task_center/task_management/detail/valdivia/:task_id',
        component: () => import('@/views/task/detail/valdivia/index.vue'),
        name: 'valdivia_task_detail',
        meta: {
          title: 'route.task_detail',
          hidden: true,
          icon: 'Stamp',
        },
      },

      {
        path: '/task_center/task_management/detail/simple:task_id',
        component: () => import('@/views/task/detail/simple/index.vue'),
        name: 'simple_task_detail',
        meta: {
          title: 'route.task_detail',
          hidden: true,
          icon: 'Stamp',
        },
      },

      {
        path: '/task_center/task_create',
        component: () => import('@/views/task/create/index.vue'),
        name: 'task_create',
        meta: {
          title: 'route.task_create',
          hidden: false,
          icon: 'DocumentAdd',
        },
      },
    ],
  },

  {
    path: '/system_center',
    component: () => import('@/layout/index.vue'),
    name: 'system_center',
    meta: {
      title: 'route.system_center',
      hidden: false,
      icon: 'Tools',
    },
    redirect: '/system_center/system_setting',
    children: [
      {
        path: '/system_center/system_setting',
        component: () => import('@/views/system/system_setting/index.vue'),
        name: 'system_setting',
        meta: {
          title: 'route.system_setting',
          hidden: false,
          icon: 'Guide',
        },
      },

      // {
      //   path: '/system_center/permission_setting',
      //   component: () => import('@/views/system/permission/index.vue'),
      //   name: 'permission_setting',
      //   meta: {
      //     title: 'route.permission_setting',
      //     hidden: false,
      //     icon: 'User',
      //   },
      // },

      {
        path: '/user_center',
        component: () => import('@/views/user/user_center/index.vue'),
        name: 'user_center',
        meta: {
          title: 'route.user_center',
          hidden: true,
          icon: 'UserFilled',
        },
      },

      {
        path: '/user_center/user_guide',
        component: () => import('@/views/user/user_guide/index.vue'),
        name: 'user_guide',
        meta: {
          title: 'route.user_guide',
          hidden: true,
          icon: 'Help',
        },
      },

      {
        path: '/user_center/feedback',
        component: () => import('@/views/user/feedback/index.vue'),
        name: 'feedback',
        meta: {
          title: 'route.feedback',
          hidden: true,
          icon: 'EditPen',
        },
      },
    ],
  },

  {
    path: '/introduction',
    component: () => import('@/layout/index.vue'),
    name: 'Introduction',
    meta: {
      title: '',
      hidden: false,
      icon: '',
    },
    children: [
      {
        path: '/introduction',
        component: () => import('@/views/introduction/index.vue'),
        name: 'About',
        meta: {
          title: 'route.About',
          hidden: false,
          icon: 'InfoFilled',
        },
      },
    ],
  },

  {
    path: '/404',
    component: () => import('@/views/404/index.vue'),
    name: '404',
    meta: {
      title: '404',
      hidden: true,
      icon: 'Male',
    },
  },
]

// 异步路由
export const asyncRoute = [
  {
    path: '/resource',
    component: () => import('@/layout/index.vue'),
    name: 'resource_center',
    meta: {
      title: 'route.resource_center',
      hidden: false,
      icon: 'GoodsFilled',
    },
    redirect: '/resource/overview',
    children: [
      {
        path: '/resource/overview/detail/:id',
        component: () => import('@/views/resource/overview/detail/index.vue'),
        name: 'scenario_detail',
        meta: {
          title: 'route.scenario_detail',
          hidden: true,
          icon: 'Stamp',
        },
      },

      {
        path: '/resource/overview/video/:id?',
        component: () => import('@/views/resource/overview/video/index.vue'),
        name: 'video_preview',
        meta: {
          title: 'route.video_preview',
          hidden: true,
          icon: 'VideoCameraFilled',
        },
      },

      {
        path: '/resource/llm',
        component: () =>
          import('@/views/resource/simulation_file/llm/index.vue'),
        name: 'llm',
        meta: {
          title: 'route.llm',
          hidden: true,
          icon: 'MagicStick',
        },
      },

      {
        path: '/resource/vehicle/config',
        component: () =>
          import('@/views/resource/vehicle_model/configure_car/index.vue'),
        name: 'vehicle_configuration',
        meta: {
          title: 'route.ConfigureVehicle',
          hidden: true,
          icon: 'Van',
        },
      },

      {
        path: '/resource/vehicle/detail/:name?',
        component: () =>
          import('@/views/resource/vehicle_model/detail/index.vue'),
        name: 'vehicle_detail',
        meta: {
          title: 'route.vehicle_info',
          hidden: true,
          icon: 'Van',
        },
      },

      {
        path: '/resource/valdivia',
        component: () => import('@/views/resource/valdivia/index.vue'),
        name: 'valdivia_config',
        meta: {
          title: 'route.valdivia_config',
          hidden: false,
          icon: 'Compass',
        },
      },
    ],
  },

  {
    path: '/task_center',
    component: () => import('@/layout/index.vue'),
    name: 'task_center',
    meta: {
      title: 'route.task_center',
      hidden: false,
      icon: 'List',
    },
    redirect: '/task_center/task_management',
    children: [
      {
        path: '/task_center/task_management/detail/valdivia/:task_id',
        component: () => import('@/views/task/detail/valdivia/index.vue'),
        name: 'valdivia_task_detail',
        meta: {
          title: 'route.task_detail',
          hidden: true,
          icon: 'Stamp',
        },
      },

      {
        path: '/task_center/task_management/detail/simple:task_id',
        component: () => import('@/views/task/detail/simple/index.vue'),
        name: 'simple_task_detail',
        meta: {
          title: 'route.task_detail',
          hidden: true,
          icon: 'Stamp',
        },
      },

      {
        path: '/task_center/task_create',
        component: () => import('@/views/task/create/index.vue'),
        name: 'task_create',
        meta: {
          title: 'route.task_create',
          hidden: false,
          icon: 'DocumentAdd',
        },
      },
    ],
  },

  {
    path: '/system_center',
    component: () => import('@/layout/index.vue'),
    name: 'system_center',
    meta: {
      title: 'route.system_center',
      hidden: false,
      icon: 'Tools',
    },
    redirect: '/system_center/system_setting',
    children: [
      {
        path: '/system_center/permission_setting',
        component: () => import('@/views/system/permission/index.vue'),
        name: 'permission_setting',
        meta: {
          title: 'route.permission_setting',
          hidden: false,
          icon: 'User',
        },
      },
    ],
  },
]

// 任意路由
export const anyRoute = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    name: 'Any',
    meta: {
      title: 'Any Route',
      hidden: true,
      icon: 'Female',
    },
  },
]
