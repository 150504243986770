<template>
  <div class="echart" ref="echart"></div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue'
import * as echarts from 'echarts/core'
import { BarChart, LineChart, PieChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
} from 'echarts/components'
import { LabelLayout, UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import type {
  BarSeriesOption,
  LineSeriesOption,
  PieSeriesOption,
} from 'echarts/charts'
import type {
  TitleComponentOption,
  TooltipComponentOption,
  GridComponentOption,
  DatasetComponentOption,
} from 'echarts/components'
import type { ComposeOption } from 'echarts/core'

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
export type ECOption = ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | PieSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DatasetComponentOption
>

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  BarChart,
  LineChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
])

let props = withDefaults(
  defineProps<{
    option: ECOption
  }>(),
  {
    option: () => ({}) as ECOption,
  },
)

// 定义组件的引用和实例变量
let echart = ref<HTMLElement | null>(null)
let echartInstance: echarts.ECharts | null = null

// 生命周期钩子，在组件挂载后初始化图表
onMounted(() => {
  if (echart.value) {
    // 初始化图表
    echartInstance = echarts.init(echart.value)
    // 使用传入的 option 配置图表
    echartInstance.setOption(props.option)
  }
})

// 生命周期钩子，在组件卸载前销毁图表实例，避免内存泄露
onUnmounted(() => {
  if (echartInstance) {
    echartInstance.dispose()
  }
})

// 监听 props.option 的变化，当配置项发生变化时，清除图表内容并重新设置配置
watch(
  () => props.option,
  () => {
    if (echartInstance) {
      echartInstance.clear()
      echartInstance.setOption(props.option)
      echartInstance.resize()
  
    }
  },
  { deep: true },
)
</script>

<style scoped lang="scss">
.echart {
  width: 100%;
  height: 100%;
}
</style>
