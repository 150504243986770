//
import { createPinia } from 'pinia'
// import piniaPersist from 'pinia-plugin-persist'

let pinia = createPinia()
// pinia.use(piniaPersist)

export default pinia

// 如果你需要持久化支持，可以参考 Pinia 的持久化插件
// import { createPinia } from 'pinia'
// import piniaPersist from 'pinia-plugin-persist'
// const pinia = createPinia()
// pinia.use(piniaPersist)