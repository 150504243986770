<template>
  <div class="body">
    <div class="top">
      <div class="title">
        <span>{{ title }}</span>
      </div>
      <div class="button-group">
        <slot></slot>
      </div>
    </div>
    <el-divider style="width: 50%; margin: 16px 0" />
    <div class="content">
      <el-table
        ref="multipleTableRef"
        :data="data"
        style="width: 100%"
        :height="tableHeight"
        highlight-current-row
      >
        <el-table-column type="selection" width="40" />
        <el-table-column
          v-for="column in columns"
          :key="column.property"
          :property="column.property"
          :label="column.label"
          :width="column.width"
        ></el-table-column>
        <el-table-column
          v-if="operation.is"
          label="Operation"
          align="center"
          :width="operation.width"
          style="padding: 0%"
        >
          <template #="{ row }">
            <el-button
              v-for="(item, index) in operation.button"
              :key="index"
              :type="item.type"
              :icon="item.icon"
              @click="() => item.click(row)"
              :disabled="item.disabled"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { ElTable } from 'element-plus'

defineProps(['title', 'data', 'tableHeight', 'columns', 'operation'])
const multipleTableRef = ref<InstanceType<typeof ElTable>>()
</script>

<style scoped lang="scss">
.body {
  margin-bottom: 30px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;

  .title {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 20px;
    font-weight: bold;
    color: $color;
    margin: 0 10px;
  }

  .button-group {
    margin-right: 20px;
  }
}
</style>
